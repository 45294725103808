window._ = require('lodash');

/* VENDOR DIRECT IMPORTS */
/*
import jszip from 'jszip';
import pdfmake from 'pdfmake';
import DataTable from 'datatables.net-bs5';
import 'datatables.net-buttons-bs5';
import 'datatables.net-buttons/js/buttons.html5.mjs';
import 'datatables.net-buttons/js/buttons.print.mjs';
import DateTime from 'datatables.net-datetime';
import 'datatables.net-fixedcolumns-bs5';
import 'datatables.net-fixedheader-bs5';
import 'datatables.net-searchbuilder-bs5';
import 'datatables.net-searchpanes-bs5';
import 'datatables.net-select-bs5';*/


import ClassicEditor from 'ckeditor5-custom-build';


/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.$ = window.jQuery = require('jquery');
    window.Popper = require('@popperjs/core');
    window.bootstrap = require('bootstrap');
    window.select2 = require('select2');
    window.bootstrapToggle = require('bootstrap-toggle');
    window.spectrum = require('spectrum-colorpicker');
    window.dayjs = require('dayjs');
    window.jszip = require('jszip');
    window.pdfmake = require('pdfmake');
    window.DataTable = require('datatables.net-bs5');
    window.DateTime = require('datatables.net-datetime');
    window.luxon = require('luxon');
    window.iziToast = require('izitoast');
    window.ClassicEditor = ClassicEditor;
    window.datepicker = require('js-datepicker');



} catch (e) { }

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';